import React, { useState } from "react";
import "./ci-part.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export default function CIpart() {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div>
      <div className="ci-container">
        <div className="ci-row">
          <div className="ci-column1">
            <div style={{ marginTop: "-10px" }}>
              <h2 className="investor-relations-sub-topic">
                Corporate Information
              </h2>
            </div>
          </div>
          <div className="ci-column2">
            <h2 className="ci-topic">NAME OF THE COMPANY</h2>
            <p className="ci-para">BPPL Holdings PLC</p>

            <h2 className="ci-topic-2">REGISTERED OFFICE</h2>
            <p className="ci-para">
              BPPL Holdings PLC
              <br />
              Level 17, Access Towers,
              <br />
              278/4 Union Place,
              <br />
              Colombo 02, Sri Lanka.
            </p>

            {/* Read More Button */}
            {!showMore && (
              <button className="ci-read-more-button" onClick={toggleShowMore}>
                Read More &nbsp; &nbsp;
                <FontAwesomeIcon icon={faCaretDown} />
              </button>
            )}
            {/* <button className="ci-read-more-button" onClick={toggleShowMore}>
              {showMore ? (
                <>
                  Read Less <FontAwesomeIcon icon={faCaretUp} />
                </>
              ) : (
                <>
                  Read More <FontAwesomeIcon icon={faCaretDown} />
                </>
              )}
            </button> */}

            {/* Hidden Content */}
            {showMore && (
              <>
                <h2 className="ci-topic-2">LEGAL FORM</h2>
                <p className="ci-para">
                  Incorporated in Sri Lanka on 26th August 1991 as a public
                  company under the Companies Act No.17 of 1982 [N (PBS) 291],
                  re-registered under the Companies Act No. 07 of 2007 on 21st
                  January 2009 (PB 859), converted to a private limited
                  liability on 20th July 2012 (PB 859 PV), converted to a public
                  company on 29th July 2016 (PB 859 PV) and subsequently
                  converted to Public Quoted Company (PB 859 PQ) on 29th June
                  2017.
                </p>

                <h2 className="ci-topic-2">
                  COMPANY REGISTRATION NUMBER (AS A PUBLIC QUOTED COMPANY)-
                </h2>
                <p className="ci-para">PB 859 PQ</p>

                <h2 className="ci-topic-2">COMPANY SECRETARY</h2>
                <p className="ci-para">
                  Secretarius (Pvt) Ltd.
                  <br />
                  3rd Floor, 40, Galle Face Court 2,
                  <br />
                  Colombo 03.
                  <br />
                  Tel: <a href="tel:+94112333431">+94 11 2333431</a>
                  <br />
                  Fax: <a href="fax:+94112381907">+94 11 2381907</a>
                </p>

                <h2 className="ci-topic-2">COMPANY REGISTRARS</h2>
                <p className="ci-para">
                  S S P Corporate Services (Private) Limited, <br />
                  101, Inner Flower Road, Colombo 03. <br />
                  Tel: <a href="tel:+94112573894">+94 11 2573894</a>
                  <br />
                  Fax: <a href="fax:+94112573609">+94 11 2573609</a>
                  <br />
                  Email: <a href="mailto:sspsec@sltnet.lk">sspsec@sltnet.lk</a>
                </p>

                <h2 className="ci-topic-2">AUDITORS TO THE COMPANY</h2>
                <p className="ci-para">
                  Messrs. Ernst & Young (Chartered Accountants) <br />
                  201, De Saram Place, <br />
                  Colombo 10.
                  <br />
                  Tel: <a href="tel:+94112204444">+94 11 2204444</a>
                  <br />
                  Fax: <a href="fax:+94112697369">+94 11 2697369</a>
                </p>

                <h2 className="ci-topic-2">BOARD OF DIRECTORS</h2>
                <p className="ci-para">
                  Mr. Sarath Dayantha Amarasinghe – Chairman <br />
                  Dr. Anush Amarasinghe – Managing Director / Chief Executive
                  Officer <br />
                  {/* Mr. Vaithilingam Selvaraj - Executive Director / Chief
                  Financial Officer <br /> */}
                  Mr. B. D. Prasad Devapriya Perera - Executive Director <br />
                  Mr. Ranil Pathirana – Non-Executive Director <br />
                  Mr. Manjula De Silva - Independent Non-Executive Director{" "}
                  <br />
                  Ms. Sharmini Ratwatte - Independent Non-Executive Director{" "}
                  <br />
                  Mr. Savantha De Saram – Independent Non-Executive Director
                </p>

                <h2 className="ci-topic-2">BANKERS OF THE COMPANY</h2>
                <p className="ci-para">
                  Bank of Ceylon <br />
                  National Development Bank <br />
                  Hatton National Bank <br />
                  Hongkong and Shanghai Banking Corporation <br />
                  Nations Trust Bank <br />
                  Sampath Bank <br />
                  Standard Chartered Bank
                </p>
              </>
            )}
            {showMore && (
              <button className="ci-read-more-button" onClick={toggleShowMore}>
                Read Less &nbsp; &nbsp;
                <FontAwesomeIcon icon={faCaretUp} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
